import React, { useContext, useState } from "react";
import { Box } from "grommet";
import { StaticImage } from "gatsby-plugin-image";
import landing from "assets/images/landing.jpg";
import AuthHeader from "./Auth/AuthHeader";
import "./authLayout.scss";

const AuthLayout = ({ children }) => {
  const imageSrc = landing;
  console.log("landing", imageSrc);
  return (
    <Box className="full_wrapper">
      <AuthHeader />
      <Box className="auth_container" direction="row">
        <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-lg space-y-10">
            {children}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default AuthLayout;
